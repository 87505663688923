/*eslint require-yield: "off"*/
import { types } from 'mobx-state-tree';

const Choice = types
  .model('Choice', {
    id: types.number,
    label: types.maybeNull(types.string),
    copy: types.maybeNull(types.string),
    image: types.maybeNull(types.string)
  })

export default Choice;
