import axios from 'axios';
import App from '../stores/App';
import Voting from '../stores/Voting';
import Logger from '../stores/Logger';

export const API_URL = "https://europe-central2-parasol-xpc.cloudfunctions.net"
export const ERROR = 1
export const NOT_VERIFIED = 2
export const VOTE_SENT = 3

class XPCApi {

	async verify_token() {
		Logger.log('XPCApi:verify_token', App.jwt_token, Voting.uid);
		const data = axios
			.post(`${API_URL}/verifyToken`, {
        anonymous_uid: Voting.uid
      }, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:verify_token:data', response.data);
        if(response.data?.signature_verified === true){
          return response.data
        }
        else{
          return NOT_VERIFIED
        }
			})
			.catch(error => {
				Logger.warn('XPCApi:verify_token:error', error);
				return ERROR
			});
		return data;
	}
  
  async send_vote(vote_object) {
		Logger.log('XPCApi:send_vote', App.jwt_token, vote_object);
		const data = axios
			.post(`${API_URL}/addVote`, vote_object, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:send_vote:data', response.data);
        return VOTE_SENT
			})
			.catch(error => {
				Logger.warn('XPCApi:send_vote:error', error);
				return ERROR
			});
		return data;
  }

}

export default new XPCApi()