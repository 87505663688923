/*eslint require-yield: "off"*/
import { types } from 'mobx-state-tree';

const User = types
	.model('User', {
		id: types.identifier,
		identity_verified: types.optional(types.boolean, false)
	})

export default User;
