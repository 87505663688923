/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger';
import Voting from './Voting';

const PreFlight = types
	.model('PreFlight', {
		did_pass_preflight: types.optional(types.boolean, false)
	})
	.actions(self => ({

		init: flow(function* () {
			Logger.log("PreFlight:init")
			self.did_pass_preflight = yield Voting.init()
      return self.did_pass_preflight
		}),

	}))
	.create();

export default PreFlight;
