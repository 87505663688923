import React from 'react';
import { observer } from 'mobx-react';

const VerifyModule = observer(
  class VerifyModule extends React.Component {
  
    render() {
      return(
        <div className="clip clip__vote-verify">
          <div className="clip__vote--header">
            <h3>Glück auf!</h3>
            <p>Bitte verifiziere deine Identität mit einem der folgenden Verfahren, um an den Votings teilzunehmen.</p>
          </div>
          <div>
            <a href="/verify" target="_blank" className="clip__vote-verify--button">Verify ID</a>
          </div>
          <div>
            <a href="/verify" target="_blank" className="clip__vote-verify--link">Mehr Informationen</a>
          </div>
        </div>
      )
    }
    
  }
);
export default VerifyModule