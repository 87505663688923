import React from 'react';
import { observer } from 'mobx-react';

const DisabledModule = observer(
  class DisabledModule extends React.Component {
  
    render() {
      return(
        <div className="clip clip__vote-app-loading">
          <div className="clip__vote-app-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="59" height="64" viewBox="0 0 59 64">
              <path fill="#fff" fillRule="evenodd" d="M59.5,89 C61.9852814,89 64,91.0147186 64,93.5 C64,95.9852814 61.9852814,98 59.5,98 C57.0147186,98 55,95.9852814 55,93.5 C55,91.0147186 57.0147186,89 59.5,89 Z M79.5,84 C81.9852814,84 84,86.0147186 84,88.5 C84,90.9852814 81.9852814,93 79.5,93 C77.0147186,93 75,90.9852814 75,88.5 C75,86.0147186 77.0147186,84 79.5,84 Z M42.5,77 C44.4329966,77 46,78.5670034 46,80.5 C46,82.4329966 44.4329966,84 42.5,84 C40.5670034,84 39,82.4329966 39,80.5 C39,78.5670034 40.5670034,77 42.5,77 Z M90.5,65 C93.5375661,65 96,67.9101491 96,71.5 C96,75.0898509 93.5375661,78 90.5,78 C87.4624339,78 85,75.0898509 85,71.5 C85,67.9101491 87.4624339,65 90.5,65 Z M40,58 C41.6568542,58 43,59.1192881 43,60.5 C43,61.8807119 41.6568542,63 40,63 C38.3431458,63 37,61.8807119 37,60.5 C37,59.1192881 38.3431458,58 40,58 Z M88,47 C91.8659932,47 95,50.1340068 95,54 C95,57.8659932 91.8659932,61 88,61 C84.1340068,61 81,57.8659932 81,54 C81,50.1340068 84.1340068,47 88,47 Z M71.5,34 C76.1944204,34 80,37.581722 80,42 C80,46.418278 76.1944204,50 71.5,50 C66.8055796,50 63,46.418278 63,42 C63,37.581722 66.8055796,34 71.5,34 Z M50,42 C51.6568542,42 53,43.1192881 53,44.5 C53,45.8807119 51.6568542,47 50,47 C48.3431458,47 47,45.8807119 47,44.5 C47,43.1192881 48.3431458,42 50,42 Z" transform="translate(-37 -34)"/>
            </svg>
          </div>
          <p>Bitte warten...</p>
        </div>
      )
    }
    
  }
);
export default DisabledModule