/*eslint require-yield: "off"*/
import dayjs from 'dayjs';
import { flow, types } from 'mobx-state-tree';
import App from './App';
import Logger from './Logger';
import Round from './models/Round';
import User from './models/User';
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, query, onSnapshot } from "firebase/firestore"; 
import XPCApi, { ERROR, NOT_VERIFIED, VOTE_SENT } from '../api/XPCApi';
import ActiveRoundData from './models/Active_Round_Data'

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export const app = initializeApp({
  apiKey: 'AIzaSyCnR4MAJv5odjnr2KBh3jzHGEnIgUwQd1M',
  authDomain: 'parasol-xpc.firebaseapp.com',
  databaseURL: 'https://parasol-xpc-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'parasol-xpc',
  storageBucket: 'parasol-xpc.appspot.com',
  messagingSenderId: '671138391297',
  appId: '1:671138391297:web:ce4a663fdd9c4786b3e700'
});

const auth = getAuth(app)
const database = getFirestore(app)
let main_db_document = null

const Voting = types
	.model('Voting', {
		uid: types.maybeNull(types.string),
		user: types.maybeNull(User),
		rounds: types.optional(types.array(Round), []),
		active_round: types.maybeNull(types.reference(Round)),
		active_round_data: types.maybeNull(ActiveRoundData),
		is_offline: types.optional(types.boolean, false),
		is_loading_directory: types.optional(types.boolean, false),
		voting_active: types.optional(types.boolean, true),
		is_loading_rounds: types.optional(types.boolean, false),
	})
	.actions(self => ({

		init: flow(function* () {
			Logger.log("Voting:init")
      const anonymous_auth = yield signInAnonymously(auth)
      Logger.log("Voting:init:anonymous_auth", anonymous_auth)
      if(anonymous_auth && anonymous_auth?.user?.uid){
        Logger.log("Voting:init:anonymous_auth:uid", anonymous_auth.user.uid)
        self.uid = anonymous_auth.user.uid
        const verified = yield XPCApi.verify_token()
        if(verified && verified !== ERROR && verified !== NOT_VERIFIED){
          self.uid = verified.docRefId
          return true
        }
      }
      return false
		}),

		hydrate: flow(function* () {
			Logger.log("Voting:hydrate", App.nice_iss_token())
      main_db_document = doc(database, 'sites', App.nice_iss_token())
      const document_snapshot = yield getDoc(main_db_document)
      if(document_snapshot.exists()){
				Logger.log("Chat:hydrate:config", document_snapshot.data())
				if (document_snapshot.data().voting_active != null) {
					self.voting_active = document_snapshot.data().voting_active
					if (!self.voting_active) {
						return false
					}
        }
        // Load user data from directory
        yield Voting.hydrate_directory()
        // TODO: Load Voting data
				if (self.has_basic_details_to_start_hydrate()) {
					yield Voting.hydrate_rounds()
				}
				else {
					return false
				}
      }
      return false
		}),

		hydrate_directory: flow(function* () {
			Logger.log("Voting:hydrate_directory")
			self.is_loading_directory = true
			const directory_doc = doc(main_db_document, 'directory', self.uid)
      const current_user = yield getDoc(query(directory_doc))
      Logger.log("Chat:hydrate_directory:query:current_user", current_user?.id)
      if (current_user?.id === self.uid) {
        self.user = {id: current_user.id, ...current_user.data()}
      }
			onSnapshot(directory_doc, (doc) => {
				Voting.update_directory(doc)
      })
      self.is_loading_directory = false
		}),
    
    update_directory: flow(function* (doc) {
			Logger.log("Voting:update_directory", doc.data())
			if (doc.id === self.uid) {
				Logger.log("Voting:update_directory:doc", doc.data())
				self.user = {id: doc.id, ...doc.data()}
			}
      return
		}),

		hydrate_rounds: flow(function* () {
			Logger.log("Voting:hydrate_rounds")
			self.is_loading_rounds = true
			const rounds_doc = doc(main_db_document, 'voting', 'rounds')
			const rounds_collection = collection(rounds_doc, 'options')
			onSnapshot(rounds_collection, (doc) => {
				Voting.update_rounds(doc.docChanges())
			})
			onSnapshot(rounds_doc, (doc) => {
				Voting.update_active_round_data(doc)
			})
			setInterval(() => {
				Voting.check_for_active_round()
			}, 5000)
      self.is_loading_rounds = false
		}),

		update_rounds: flow(function* (data) {
			Logger.log("Voting:update_rounds", data.length)
      data.forEach((change) => {
        if(change.type !== "modified" && change.type !== "added"){
          return
        }
        const doc = change.doc
        Logger.log("Voting:update_rounds:doc", doc.data())
        const existing_round = self.rounds.find(p => p.id === doc.id)
				if(existing_round){
					existing_round.update({id: doc.id, ...doc.data()})
				}
				else{
					self.rounds.push({id: doc.id, ...doc.data()})
				}
      })
      return
		}),

		update_active_round_data: flow(function* (doc) {
			Logger.log("Voting:update_active_round_data", doc.data())
			if (self.active_round_data) {
				self.active_round_data.update(self.transform_doc_data(doc))
			}
			else {
				self.active_round_data = self.transform_doc_data(doc)
			}
			Voting.check_for_active_round()
      return
		}),

		check_for_active_round: flow(function* () {
			Logger.log("Voting:check_for_active_round")
			if (self.active_round_data && self.active_round_data.is_round_active) {
				if (self.active_round == null) {
					const existing_round = self.rounds.find(round => round.uid === self.active_round_data.round_id)
					if (existing_round && self.active_round_data.is_round_within_time_bounds()) {
						existing_round.set_start_and_end_time(self.active_round_data.starts, self.active_round_data.ends)
						self.active_round = existing_round
					}
				}
				else {
					const existing_round = self.rounds.find(round => round.uid === self.active_round_data.round_id)
					if (existing_round) {
						existing_round.set_start_and_end_time(self.active_round_data.starts, self.active_round_data.ends)
						if (self.active_round?.id !== existing_round.id) {
							self.active_round = existing_round
						}
					}
				}
			}
			else {
				self.active_round = null
			}
		}),

		set_offline_status: flow(function* () {
			Logger.log("Voting:is_offline")
			self.is_offline = true
		}),
		
		send_vote_for_round: flow(function* (round) {
			Logger.log("Voting:send_vote_for_round", round, round.return_round_vote_object())
			const vote_result = yield XPCApi.send_vote(round.return_round_vote_object())
			Logger.log("Voting:send_vote_for_round:vote_result", vote_result)
			if (vote_result === VOTE_SENT) {
				return true
			}
			else if (vote_result === ERROR) {
				return "try_again"
			}
			return false
		}),
		
		check_rounds_for_vote_status_and_mark_sent: flow(function* () {
			Logger.log("Voting:check_rounds_for_vote_status_and_mark_sent", self.matching_rounds_for_app_votes())
			// self.matching_rounds_for_app_votes().forEach(round => {
			// 	round.toggle_round_stage("sent")
			// })
		}),
		
		check_for_connection: flow(function* () {
			Logger.log("Voting:check_for_connection")
		}),

	}))
	.views(self => ({
		can_vote(){
			return self.user?.identity_verified
		},
		has_basic_details_to_start_hydrate(){
			return self.user && !self.is_loading_directory
		},
		matching_rounds_for_vote_slips(){
			return self.rounds
		},
		matching_rounds_for_app_votes(){
			return this.matching_rounds_for_vote_slips()
		},
		has_active_round(){
			return self.active_round
		},
		did_vote(){
			return false
		},
		transform_doc_data(doc) {
      let data = doc.data()
			if (data.active_round) {
				data.round_id = data.active_round
			}
      if (data.active_round_start) {
        data.starts = data.active_round_start.toDate()
      }
      if (data.active_round_end) {
        data.ends = data.active_round_end.toDate()
			}
			if (data.active_round_duration) {
        data.duration = data.active_round_duration
      }
      return data
		},
		has_currently_active_round_data() {
			return self.active_round_data && self.active_round_data.is_round_active && self.active_round_data.is_round_within_time_bounds()
		}
	}))
	.create();

export default Voting;
