import React from 'react';
import { observer } from 'mobx-react';
import Logger from '../stores/Logger'
import { zeroPad, calcTimeDelta } from 'react-countdown';
import Voting from '../stores/Voting'

// This is the main starting point, where it all begins.
const CountdownComponent = observer(
  class CountdownComponent extends React.Component {
    
    constructor(props){
      super(props)
      this.state = {
        render: null
      }
      this.interval = null
    }

    componentDidMount = () => {
      Logger.log("CountdownComponent:did_mount", this.props)
      this._on_tick()
      this.interval = setInterval(() => {
        this._on_tick()
      }, 350)
    }
    
    componentWillUnmount = () => {
      clearInterval(this.interval)
    }
    
    _on_tick = () => {
      const { total, hours, minutes, seconds, completed } = calcTimeDelta(this.props.end_date)
      if (completed) {
        clearInterval(this.interval)
        this.setState({render: null})
        Voting.active_round.check_round_is_within_time(true)
      } else {
        const wording = hours > 0 ? "Stunden" : minutes > 0 ? "Minuten" : seconds > 0 ? "Sekunden" : ""
        if(this.props.type === "line"){
          this.setState({
            render: <div className="clip__vote-round--countdown-container">
              <span className="clip__vote-round--countdown-strip">
                <span style={{width: `${Voting.active_round.time_percentage_difference(total)}%`}}></span>
              </span>
              <span className="clip__vote-round--countdown">{hours > 0 ? `${zeroPad(hours)}:` : null}{zeroPad(minutes)}:{zeroPad(seconds)}</span>
            </div>
          })
        }
        else{
          this.setState({
            render: <span><span className="clip__vote-round--countdown">{hours > 0 ? `${zeroPad(hours)}:` : null}{zeroPad(minutes)}:{zeroPad(seconds)}</span> {wording}</span>
          })
        }
        setTimeout(() => {
          Voting.active_round.check_round_is_within_time()
        }, 300)
      }
      
    }
  
    render() {
      return this.state.render !== null ? this.state.render : null
    }
  }
);
export default CountdownComponent