import React from 'react';
import { observer } from 'mobx-react';
import App from '../stores/App'
import Voting from '../stores/Voting'
import LoadingModule from './Loading'
import VerifyModule from './Verify'
import DisabledModule from './Disabled'
import OfflineModule from './Offline'
import NoActiveVoteModule from './Voting/NoActiveVote'
import ActiveVoteModule from './Voting/ActiveVote'

// This is the main starting point, where it all begins.
const AppModule = observer(
	class AppModule extends React.Component {

		componentDidMount = async () => {
			App.hydrate(this.props.element)
		}
		
		_return_section = () => {
			if(App.is_disabled){
				return <DisabledModule />
			}
			else if(App.is_loading){
				return <LoadingModule />
			}
			else if(!Voting.can_vote()){
				return <VerifyModule />
			}
			else if(Voting.can_vote() && Voting.is_offline){
				return <OfflineModule />
			}
			else if(Voting.can_vote() && !Voting.has_active_round()){
				return <NoActiveVoteModule />
			}
			else if(Voting.can_vote() && Voting.has_active_round()){
				return <ActiveVoteModule />
			}
			else{
				return null
			}
		}
	
		render() {
			return(
				<div className="clip clip__vote-container">
					<button
						onClick={App.toggle_container_open}
						className="clip__vote-container--toggle"
					>
						{App.container_is_open ? 
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg> 
							: 
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polyline points="18 15 12 9 6 15"></polyline></svg>
						}
					</button>
					{this._return_section()}
				</div>
			)
		}
	}
);
export default AppModule