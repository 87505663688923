import 'react-app-polyfill/ie11';
import './utils/polyfill';
import './utils/cookies';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './modules/App'
import DebugModule from './modules/Debug'

const element = document.querySelector('[data-react-clip="voting-module"]')

if (element) {
  ReactDOM.render(
    <React.StrictMode>
      <App element={element} />
      <DebugModule />
    </React.StrictMode>,
    element
  );
}
