import React from 'react';
import { observer } from 'mobx-react';
import App from '../stores/App'
import Voting from '../stores/Voting'

// This is the main starting point, where it all begins.
const DebugModule = observer(
  class DebugModule extends React.Component {
  
    render() {
      if(App.debug_enabled){
        if(App.debug_container_visible){
          return(
            <div className="clip clip__vote-debug">
              <p>App loading? <b>{ App.is_loading ? "Yes" : "No" }</b></p>
              <p>App did error? <b>{ App.error !== "" ? "Yes" : "No" }</b></p>
              <p>App error: <b>{ App.error !== "" ? App.error : "N/A" }</b></p>
              <p>&nbsp;</p>
              <p>User can vote? <b>{ Voting.can_vote() ? "Yep" : "Nope"}</b></p>
              <p>Available Rounds: <b>{ Voting.rounds.length }</b></p>
              <p>&nbsp;</p>
              <p>Available active round data: <b>{Voting.active_round_data && Voting.has_currently_active_round_data() ? Voting.active_round_data.round_id : "N/A"}</b></p>
              <p>Round data duration: <b>{Voting.active_round_data && Voting.has_currently_active_round_data() ? Voting.active_round_data.round_duration() : "N/A"}</b></p>
              <p>Currently set active round: <b>{Voting.active_round ? Voting.active_round.id : "N/A"}</b></p>
              <button onClick={App.toggle_debug_container_visible}>Hide debug <span role="img" aria-label="Mind blown">🤯</span></button>
            </div>
          )
        }
        return(
          <div className="clip clip__vote-debug hidden">
            <button onClick={App.toggle_debug_container_visible}>Show debug <span role="img" aria-label="OMG what is going on">😱</span></button>
          </div>
        )
        
      }
      return null
    }
    
  }
);
export default DebugModule