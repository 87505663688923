/*eslint require-yield: "off"*/
import { types, flow, clone, applySnapshot } from 'mobx-state-tree';
import Logger from './../Logger';
import Choice from './Choice';
import dayjs from 'dayjs';
import Voting from './../Voting';

const Round = types
  .model('Round', {
    id: types.identifier,
    starts: types.maybeNull(types.Date),
    ends: types.maybeNull(types.Date),
    type: types.maybeNull(types.string),
    question: types.maybeNull(types.string),
    copy: types.maybeNull(types.string),
    choices: types.optional(types.array(Choice), []),
    selected_choices: types.optional(types.array(Choice), []),
    max_choice_count: types.optional(types.number, 1),
    stage: types.optional(types.string, "select"), // Select, Confirm, Sent
    is_sending: types.optional(types.boolean, false),
    uid: types.maybeNull(types.string),
    title_is_hidden: types.optional(types.boolean, false)
  })
  .actions(self => ({

    update: flow(function* (data) {
      Logger.log("Round:update", data)
      data.starts = self.starts
      data.ends = self.ends
      data.stage = self.stage
      applySnapshot(self, data)
    }),
    
    set_start_and_end_time: flow(function* (starts, ends) {
      Logger.log("Round:set_start_and_end_time", starts, ends)
      self.starts = starts
      self.ends = ends
      
      if(self.is_round_within_time_bounds() && self.stage !== "confirm" && self.stage !== "sent"){
        self.stage = "select"
      }
      else{
        self.check_round_is_within_time()
      }
      
    }),
    
    toggle_choice_select: flow(function* (choice) {
      Logger.log("Round:toggle_choice_select", choice, self.selected_choices)
      const existing_choice_index = self.selected_choices.findIndex(c => c.id === choice.id)
      if(existing_choice_index > -1){
        self.selected_choices.splice(existing_choice_index, 1)
      }
      else if(self.selected_choices.length < self.max_choice_count){
        const new_choice = clone(choice)
        self.selected_choices.push(new_choice)
      }
      else if(self.max_choice_count === 1){
        self.selected_choices.splice(existing_choice_index, 1)
        const new_choice = clone(choice)
        self.selected_choices.push(new_choice)
      }
      
      self.check_round_is_within_time()
    }),
    
    toggle_round_stage: flow(function* (stage = "select") {
      Logger.log("Round:toggle_round_stage", stage)
      self.stage = stage
      self.check_round_is_within_time()
    }),
    
    send_vote: flow(function* () {
      Logger.log("Round:send_vote")
      if(self.stage !== "out-of-time" && self.stage !== "sent" && !self.is_sending){
        self.is_sending = true
        const vote = yield Voting.send_vote_for_round(self)
        if(vote === "try_again"){
          self.is_sending = false
        }
        else if(vote !== "try_again" && vote){
          self.stage = "sent"
        }
        else{
          self.stage = "confirm"
        }
        self.is_sending = false
      }
    }),
    
    check_round_is_within_time: flow(function* (ticker_is_over = false) {
      Logger.log("Round:check_round_is_within_time", self.is_round_within_time_bounds())
      if((!self.is_round_within_time_bounds() || ticker_is_over) && self.stage !== "sent"){
        self.stage = "out-of-time"
      }
      else if(!self.is_round_within_time_bounds() && self.stage === "sent" ) {
        self.title_is_hidden = true
      }
    })
    
  }))
  .views(self => ({
    
    start_time(){
      return dayjs(self.starts)
    },
    
    nice_start_time(){
      return this.start_time().format("HH:mm:ss")
    },
    
    end_time(){
      return dayjs(self.ends)
    },
    
    nice_end_time(){
      return this.end_time().format("HH:mm:ss")
    },
    
    is_round_within_time_bounds(){
      return dayjs().isBetween(this.start_time(), this.end_time())
    },
    
    is_selected_choice(choice_id){
      return self.selected_choices.find(c => c.id === choice_id) != null
    },
    
    votes_available_text(){
      return `${self.selected_choices.length}/${self.max_choice_count} choices available`
    },
    
    votes_available(breakword = "/"){
      return `${self.selected_choices.length}${breakword}${self.max_choice_count}`
    },
    
    votes_available_reverse(breakword = "/"){
      return `${self.max_choice_count - self.selected_choices.length}${breakword}${self.max_choice_count}`
    },
    
    time_difference(){
      return this.end_time().diff(this.start_time())
    },
    
    time_percentage_difference(remaining){
      const difference = this.time_difference()
      const percentage = (1 - (remaining / difference)) * 100
      return percentage
    },
    
    did_select_all_multi_select(){
      return self.selected_choices.length === self.max_choice_count && self.max_choice_count > 1
    },

    return_round_vote_object(){
      let choices_array = []
      self.selected_choices.forEach((choice) => {
        choices_array.push(choice.id)
      })
      const vote_object = {
        choice_ids: choices_array,
        round_uid: self.id
      }
      return vote_object
		},
    
  }))

export default Round;
