/*eslint require-yield: "off"*/
import { types, flow, applySnapshot } from 'mobx-state-tree';
import dayjs from 'dayjs';
import Logger from '../Logger'
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const ActiveRoundData = types
  .model('ActiveRoundData', {
    round_id: types.maybeNull(types.string),
    starts: types.maybeNull(types.Date),
    ends: types.maybeNull(types.Date),
    duration: types.maybeNull(types.number),
    is_round_active: types.optional(types.boolean, false)
  })
	.actions(self => ({
		
    update: flow(function* (data) {
      Logger.log("ActiveRoundData:update", data)
      applySnapshot(self, data)
      self.end_time()
    }),
    
    set_end_date: flow(function* (date) {
      Logger.log("ActiveRoundData:set_end_date", date)
      self.ends = date
		}),
		
  }))
  .views(self => ({
    
    start_time(){
      return dayjs(self.starts)
    },
    
    nice_start_time(){
      return this.start_time().format("HH:mm:ss")
    },
    
    end_time() {
      if (self.ends) {
        return dayjs(self.ends)
      }
      else {
        const end_date = this.start_time().add(self.duration, "seconds")
        self.set_end_date(end_date.toDate())
        return end_date
      }
    },
    
    nice_end_time(){
      return this.end_time().format("HH:mm:ss")
    },
    
    is_round_within_time_bounds(){
      return dayjs().isBetween(this.start_time(), this.end_time())
    },

    round_duration() {
      return dayjs.duration(this.end_time().diff(this.start_time())).asSeconds()
    }
    
  }))

export default ActiveRoundData;
